<dx-data-grid
  #grid
  [dataSource]="dataSource"
  [keyExpr]="config.keyExpr"
  [showBorders]="false"
  (onRowPrepared)="onRowPrepared($event)"
  (onCellClick)="onRowClick($event)"
  (onOptionChanged)="handlePropertyChange($event)"
  (onInitialized)="onInitialized()"
>
  <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false">
    <!-- empty string is required to disable delete confirmation popup -->
    <dxo-texts confirmDeleteMessage=""> </dxo-texts>
  </dxo-editing>

  <ng-container *ngFor="let column of columns">
    <dxi-column
      *ngIf="!config.hideEmptyColumns || nonEmptyColumns.has(column.dataField)"
      cssClass="_column"
      [dataField]="column.dataField"
      [dataType]="column.dataType"
      [caption]="column.captionKey | translate"
      [alignment]="column.alignment"
      [format]="column.format"
      [allowSorting]="false"
      [headerCellTemplate]="column.customHeaderTemplate ?? 'headerTemplate'"
      [width]="column.width"
    >
    </dxi-column>
  </ng-container>

  <dxo-master-detail [enabled]="config.subGridEnabled" template="detail"></dxo-master-detail>
  <div *dxTemplate="let d of 'detail'">
    <app-sub-grid
      #subgrid
      [config]="d.data[config.subGridConfigKey]"
      [columns]="subGridColumns ?? d.data[config.subGridColumnsKey]"
      [dataSource]="d.data[config.subGridDataSourceKey]"
      [mainGridData]="d.data"
      [minMaxHeaderValues]="
        d.data[config.subGridColumnsKey] ? createMinMaxHeaderValues(d.data[config.subGridColumnsKey]) : minMaxHeaderValues
      "
      (addRow)="onAddRow($event)"
      (updateRow)="onUpdateRow($event)"
      (deleteRow)="onDeleteRow($event)"
      (initNewRow)="onInitNewRow($event)"
      (updateMinMaxColumns)="onUpdateMinMaxColumns($event)"
    >
    </app-sub-grid>
  </div>

  <div *dxTemplate="let h of 'headerTemplate'" class="_header d-flex">
    <span class="_caption">{{ h.column.caption }}</span>
  </div>

  <div *dxTemplate="let c of customHeaderTemplate.sort" class="_sort-header">
    <div class="row no-gutters align-items-end">
      <div class="col-auto mr-1">
        <div class="_caption">{{ c.column.caption }}</div>
      </div>
      <div class="col">
        <button class="btn _btn-sorting" type="button" (click)="toggleSort(c)">
          <div class="_arrow" [ngClass]="{ active: currentSort.dataField === c.column.dataField && !currentSort.desc }">
            <i class="material-icons">arrow_drop_up</i>
          </div>
          <div class="_arrow" [ngClass]="{ active: currentSort.dataField === c.column.dataField && currentSort.desc }">
            <i class="material-icons">arrow_drop_down</i>
          </div>
        </button>
      </div>
    </div>
  </div>
</dx-data-grid>
