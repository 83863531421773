import { Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  AbstractControl,
  UntypedFormArray,
  ValidatorFn,
  AsyncValidatorFn
} from '@angular/forms';

import { LoggerService, ILogger } from './logger.service';

export const identificationNumberPattern = '^(0|[1-9][0-9]*)$';

const getProperty = (path, object) => path.reduce((xs, x) => (xs && xs[x] != null ? xs[x] : null), object);

const setProperty = (path, value, object) => {
  for (var i = 0; i < path.length - 1; i++) {
    const key = path[i];
    if (!Object.prototype.hasOwnProperty.call(object, key)) {
      object[key] = {};
    }
    object = object[key];
  }

  object[path[i]] = value;
};

@Injectable()
export class FormService {
  private logger: ILogger;

  constructor(private formBuilder: UntypedFormBuilder, private loggerService: LoggerService) {
    this.logger = loggerService.getLogger('FormService');
  }

  public form(controlsConfig: { [key: string]: any }, messages?: { [key: string]: any }, extra?: { [key: string]: any }): Form {
    this.logger.debug('Creating form');

    if (!messages) {
      messages = {};
    }

    const controls = this.reduceControls(controlsConfig);
    const validator: ValidatorFn = extra != null ? extra['validator'] : null;
    const asyncValidator: AsyncValidatorFn = extra != null ? extra['asyncValidator'] : null;

    this.logger.debug('Controls processed, returning Form');

    return new Form(controls, messages, this.loggerService.getLogger('Form'), validator, asyncValidator);
  }

  public group(controlsConfig: { [key: string]: any }, extra?: { [key: string]: any }): UntypedFormGroup {
    return this.formBuilder.group(controlsConfig, extra);
  }

  public array(controlsConfig: any[], validator?: ValidatorFn | null, asyncValidator?: AsyncValidatorFn | null): UntypedFormArray {
    return this.formBuilder.array(controlsConfig, validator, asyncValidator);
  }

  private reduceControls(controlsConfig: { [k: string]: any }): { [key: string]: AbstractControl } {
    const controls: { [key: string]: AbstractControl } = {};
    Object.keys(controlsConfig).forEach((controlName) => {
      controls[controlName] = this.createControl(controlsConfig[controlName]);
    });
    return controls;
  }

  private createControl(controlConfig: any): AbstractControl {
    if (
      controlConfig instanceof UntypedFormControl ||
      controlConfig instanceof UntypedFormGroup ||
      controlConfig instanceof UntypedFormArray
    ) {
      return controlConfig;
    } else if (Array.isArray(controlConfig)) {
      const value = controlConfig[0];
      const validator: ValidatorFn = controlConfig.length > 1 ? controlConfig[1] : null;
      const asyncValidator: AsyncValidatorFn = controlConfig.length > 2 ? controlConfig[2] : null;
      return this.formBuilder.control(value, validator, asyncValidator);
    } else {
      return this.formBuilder.control(controlConfig);
    }
  }
}

export class Form extends UntypedFormGroup {
  public get alerts(): { [key: string]: any } {
    return this.formAlerts;
  }

  private logger: ILogger;
  private formAlerts: { [key: string]: any };
  private messages: { [key: string]: any };

  constructor(
    controlsConfig: { [key: string]: AbstractControl },
    messages: { [key: string]: any },
    logger: ILogger,
    validator?: ValidatorFn,
    asyncValidator?: AsyncValidatorFn
  ) {
    super(controlsConfig, validator, asyncValidator);

    this.logger = logger;
    this.messages = messages;

    this.valueChanges.subscribe(() => this.validate());
    this.validate();
  }

  public canSubmit(): boolean {
    this.logger.debug('Determining if form is valid and can be submitted');

    if (!this.valid) {
      this.logger.debug('Form is invalid. Determining error messages.');
      this.validate(true);
    } else {
      this.logger.debug('Form is valid.');
    }

    return this.valid;
  }

  public updateValidity(): void {
    this.logger.debug('Updating form validity');

    this.validate(true);
  }

  private validate(submit?: boolean) {
    this.formAlerts = {};
    this.iterateFormGroup([], Object.keys(this.controls), submit);
  }

  private iterateFormGroup(path: string[], keys: string[], submit: boolean): void {
    keys.forEach((key) => {
      const field = Array.from(path);
      field.push(key);

      const control: AbstractControl = this.get(field);

      if (submit) {
        control.markAsDirty();
      }

      if (control && control.dirty && control.enabled && !control.valid) {
        const messages = getProperty(field, this.messages);
        const errors = [];

        for (const errorKey in control.errors) {
          if (messages && messages[errorKey]) {
            errors.push(messages[errorKey]);
          } else {
            this.logger.error('Key "{0}.{1}" doesn\'t exist in form errors object', field.toString().replace(/[\,]/g, '.'), errorKey);
          }
        }

        if (errors.length > 0) {
          setProperty(field, errors, this.formAlerts);
        }
      }

      if (control instanceof UntypedFormGroup) {
        const formGroup = control as UntypedFormGroup;
        this.iterateFormGroup(field, Object.keys(formGroup.controls), submit);
      }
    });
  }
}
