import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { CustomHeaderTemplate } from '../custom-header.enum';
import { IGridColumn } from '../grid-column.interface';
import { IGridConfig } from '../grid-config.interface';
import { MinMaxColumns, SubGridUpdate } from '../sub-grid-update';
import _ from 'lodash';

@Component({
  selector: 'app-sub-grid',
  templateUrl: './sub-grid.component.html',
  styleUrls: ['./sub-grid.component.scss']
})
export class SubGridComponent {
  @ViewChild('subgrid', { static: true })
  grid: DxDataGridComponent;

  @Input()
  config: IGridConfig;

  @Input()
  columns: IGridColumn[] = [];

  @Input()
  mainGridData: any;

  @Input()
  minMaxHeaderValues: MinMaxColumns = {};

  showMinMaxEdit = false;

  newRowPosition = 'last';

  @Output()
  updateRow = new EventEmitter<SubGridUpdate>();

  @Output()
  addRow = new EventEmitter<SubGridUpdate>();

  @Output()
  deleteRow = new EventEmitter<SubGridUpdate>();

  @Output()
  initNewRow = new EventEmitter<SubGridUpdate>();

  @Output()
  updateMinMaxColumns = new EventEmitter<SubGridUpdate>();

  readonly customHeaderTemplate = CustomHeaderTemplate;

  readonly currentSort = { dataField: '', desc: false };
  nonEmptyColumns: Set<string> = new Set();

  private _dataSource: any[] = [];

  // we only prevent saving when user tries to enter a valid number with a comma
  private validateNumberRegex = new RegExp(/^\d*\,{1}\d+$/);

  get dataSource(): any[] {
    return this._dataSource;
  }

  @Input()
  set dataSource(value: any[]) {
    this._dataSource = value;
    if (this.config.hideEmptyColumns) {
      this.nonEmptyColumns = value?.reduce((acc, curr) => new Set<string>([...acc, ...Object.keys(curr)]), new Set<string>());
    }
  }

  handleDecimalComma: any;

  constructor() {
    const self = this;
    this.handleDecimalComma = function (rowData: any, value: any) {
      // 'this' is column in this function's context
      if (value && self.validateNumberRegex.test(value.toString())) {
        value = _.replace(value.toString(), ',', '.');
      }
      rowData[this.dataField] = value;
    };
  }

  onRowPrepared(e: any) {
    if (e.rowType === 'data') {
      const commandExpand = e.rowElement.querySelector('.dx-command-expand');
      if (commandExpand && !e.data[this.config.subGridDataSourceKey]?.length) {
        commandExpand.firstChild.classList.remove('dx-datagrid-group-closed');
        commandExpand.firstChild.classList.remove('dx-datagrid-group-opened');
      }
    }
  }

  onCellPrepared(e: any) {
    if (e.rowType === 'data' && e.column.command === 'expand') {
      if (!e.data[this.config.subGridDataSourceKey]?.length) {
        e.cellElement.classList.remove('dx-datagrid-expand');
      }
    }
  }

  onGridInitialized() {
    if (this.config.gridActionsEnabled) {
      this.grid?.instance.addRow();
    }
  }

  onInitNewRow(e: any) {
    // not generic
    e.data.testDate = new Date();
    this.initNewRow.emit({
      alteredRow: e.data,
      mainGridData: this.mainGridData,
      columns: this.columns,
      minMaxColumns: this.minMaxHeaderValues
    });
  }

  onRowAddClick() {
    this.grid?.instance.addRow();
  }

  onRowClick(e: any) {
    if (!this.config.gridActionsEnabled) {
      return;
    }
    this.grid.instance.editRow(e.rowIndex);
  }

  onRowUpdated(e: any): void {
    this.updateRow.emit({
      alteredRow: e.data,
      mainGridData: this.mainGridData,
      columns: this.columns,
      minMaxColumns: this.minMaxHeaderValues
    });
    this.grid?.instance.addRow();
  }

  onRowInserted(e: any): void {
    this.addRow.emit({
      alteredRow: e.data,
      mainGridData: this.mainGridData,
      columns: this.columns,
      minMaxColumns: this.minMaxHeaderValues
    });
    this.grid?.instance.addRow();
  }

  onRowRemoved(e: any): void {
    if (e.data.measurementId) {
      this.deleteRow.emit({ alteredRowKey: e.data.measurementId, mainGridData: this.mainGridData, minMaxColumns: this.minMaxHeaderValues });
    } else {
      this.deleteRow.emit(null);
    }
  }

  onDeleteClick(cellinfo: any) {
    this.grid.instance.cancelEditData();
    this.grid.instance.deleteRow(cellinfo.row.rowIndex);
    this.grid.instance.addRow();
  }

  onSaveClick(cellinfo: any) {
    if (!cellinfo.element.querySelector('.dx-invalid')) {
      this.grid.instance.saveEditData();
    }
  }

  onCancelClick() {
    this.grid.instance.cancelEditData();
  }

  openMinMaxEdit() {
    this.showMinMaxEdit = true;
  }

  saveMinMaxEdit(columns: MinMaxColumns) {
    this.showMinMaxEdit = false;
    if (columns) {
      this.minMaxHeaderValues = columns;
      this.updateMinMaxColumns.emit({ minMaxColumns: columns, mainGridData: this.mainGridData });
    }
  }
}
